<template>
  <div class="flex flex-col justify-between h-full">
    <ul class="pb-4 space-y-1 list-none w-full flex-grow">
      <menu-item
        :label="t('ttmt.navigations.items.privacy_policy')"
        :icon-props="{ library: 'coolicon', name: 'info_circle_outline' }"
        @clicked="privacyPolicy"
      />

      <menu-item
        :label="t('ttmt.navigations.items.terms_conditions')"
        :icon-props="{ library: 'coolicon', name: 'info_circle_outline' }"
        @clicked="termsConditions"
      />

      <menu-item
        :label="t('ttmt.navigations.items.cookies')"
        :icon-props="{ library: 'coolicon', name: 'cookie' }"
        @clicked="cookies"
      />

      <menu-item
        :label="t('ttmt.navigations.items.partners')"
        :icon-props="{ library: 'fontawesome', name: 'hands-helping' }"
        @clicked="partners"
      />

      <menu-item
        :label="t('ttmt.navigations.items.customers')"
        :icon-props="{ library: 'fontawesome', name: 'hands-helping' }"
        @clicked="customers"
      />

      <menu-item
        :label="t('ttmt.navigations.items.faqs')"
        :icon-props="{ library: 'coolicon', name: 'help_circle_outline' }"
        @clicked="faqs"
      />

      <menu-item
        :label="t('ttmt.navigations.items.contact_us')"
        :icon-props="{ library: 'coolicon', name: 'chat' }"
        @clicked="supportRequests"
      />

      <menu-item
        :label="t('ttmt.navigations.items.credits')"
        :icon-props="{ library: 'coolicon', name: 'data' }"
        @clicked="credits"
      />
    </ul>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import MenuItem from '@app/components/features/menu/MenuItem.vue'

const router = useRouter()
const { t } = useI18n()

// ---------- ROUTES ----------

async function privacyPolicy() {
  await router.push({ name: 'index-privacy-policy' })
}
async function termsConditions() {
  await router.push({ name: 'index-terms-condition' })
}
async function partners() {
  await router.push({ name: 'index-partners' })
}
async function customers() {
  await router.push({ name: 'index-customers' })
}
async function faqs() {
  await router.push({ name: 'index-faq-categories' })
}
async function supportRequests() {
  await router.push({ name: 'index-support-requests' })
}
async function cookies() {
  await router.push({ name: 'show-cookies' })
}
async function credits() {
  await router.push({ name: 'index-credits' })
}
</script>
