<template>
  <li>
    <a
      class="flex items-center h-11 w-fit"
      href="#"
      @click.prevent="handleClick"
    >
      <span>
        <app-icon
          v-if="iconProps?.name"
          v-bind="iconPropsToUse"
        />
      </span>

      <slot>
        <span class="ml-4 font-bold">
          {{ label }}
        </span>
      </slot>
    </a>
  </li>
</template>

<script setup>
import { computed } from 'vue'

import AppIcon from '@shared/components/ui/AppIcon.vue'

const props = defineProps({
  // Text displayed
  label: {
    type: String,
    default: '',
  },
  // AppIcon component's props
  iconProps: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'clicked',
])

function handleClick() {
  emits('clicked')
}

const iconPropsToUse = computed(() => {
  const iconProps = props.iconProps

  // add custom classes
  iconProps.classes = [
    ...iconProps.classes ?? [],
    'text-theme-500',
  ]

  return iconProps
})
</script>
